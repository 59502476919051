import React, { useContext, useState, useEffect } from "react";
import { items } from "../components/AllData.js";
import TrendingSlider from "../components/TrendingSlider.jsx";
import Newsletter from "../components/Newsletter.jsx";
import Footer from "../components/Footer.jsx";
import Navbar from "../components/Navbar.jsx";
import { useParams } from "react-router-dom";
import { CartContext } from "../App";
import "../pages/ProductPage.css";

// Function to render stars based on the rating
const renderStars = (rating) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < Math.floor(rating)) {
      stars.push(<span key={i} className="star filled">★</span>); // Fully filled star
    } else if (i < rating) {
      stars.push(<span key={i} className="star half-filled">★</span>); // Half-filled star
    } else {
      stars.push(<span key={i} className="star">☆</span>); // Empty star
    }
  }
  return stars;
};


// Function to calculate average rating from reviews
const calculateAverageRating = (reviews) => {
  if (!reviews || reviews.length === 0) return 0; // Default to 0 if no reviews
  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  return (totalRating / reviews.length).toFixed(1); // Return average with 1 decimal place
};

// Render individual reviews
const renderReviews = (reviews) => {
  if (!reviews || reviews.length === 0) return <p className="no-reviews">No reviews yet.</p>;
  return reviews.map((review) => (
    <div key={review.id} className="review">
    <div className="reviewer-name-date">
      <p className="reviewer-name"><strong>{review.reviewerName}</strong></p>
      <p className="reviewer-date"><strong>{review.date}</strong></p>
      </div>
      <div className="review-rating">{renderStars(review.rating)}</div>
      <p className="review-comment">{review.comment}</p>
    </div>
  ));
};

function ProductPage() {
  const { id } = useParams();
  const item = items.find((item) => item.id === parseInt(id)); // Fetch item from AllData.js
  const { addToCart } = useContext(CartContext);

  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState(item?.sizes ? item.sizes[0] : "");
  const [selectedColor, setSelectedColor] = useState(item?.colors?.[0]?.color || "");
  const [notify, setNotify] = useState(false);

  const averageRating = calculateAverageRating(item?.reviews); // Calculate average rating

  useEffect(() => {
    if (item?.sizes?.length > 0) {
      setSelectedSize(item.sizes[0].size || item.sizes[0]); // Handles both object and string size formats
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      const defaultColor = item.colors && item.colors[0];
      setImage(defaultColor?.img || item.img);
      setAdditionalImages(defaultColor?.additionalImgs || []);
    }
  }, [item]);  

  const handleColorChange = (color, img, additionalImgs) => {
    setSelectedColor(color);
    setImage(img);
    setAdditionalImages(additionalImgs || []);
  };

  const calcPrice = () => {
    if (item?.prices) {
      return item.prices[selectedSize];
    } else {
      return item?.price;
    }
  };

  const handleAddToCart = () => {
    const price = calcPrice();
    addToCart({
      ...item,
      quantity,
      size: selectedSize,
      color: selectedColor,
      img: image,
      price,
    });
    setNotify(true);
    setTimeout(() => setNotify(false), 2000);
  };

  if (!item) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <div className={`notify ${notify ? "slide-in" : ""}`}>
        <p>Item has been added to the cart ✅</p>
      </div>

      <div className="product-page-div">
        <div className="container">
          <div className="product-div">
            <h3 className="product-big-name">{item.description}</h3>
            <div className="product-left">
              <div className="big-img">
                <img src={image} alt="product" />
              </div>
              <div className="small-imgs">
  {/* Default image */}
  {/* <img
    src={item.img}
    alt="default"
    onMouseOver={(e) => setImage(e.target.src)}
  /> */}
  {/* Additional images */}
  {additionalImages.map((imgSrc, index) => (
    <img
      key={index}
      src={imgSrc}
      alt={`preview-${index}`}
      onMouseOver={(e) => setImage(e.target.src)}
    />
  ))}
</div>


            </div>
            <div className="product-right">
              <p className="product-spec">{item.specs}</p>

              {/* Render the average rating and total reviews */}
              <div className="product-rating">
                <p>{renderStars(averageRating)} ({averageRating}) {item.reviews?.length || 0} Reviews</p>
              </div>

              {item.sizes && (
                <div className="product-size">
                  <p>Select Size:</p>
                  <div className="size-buttons">
                    {item.sizes.map((size, index) => (
                      <button
                        key={index}
                        onClick={() => setSelectedSize(size)}
                        className={selectedSize === size ? "selected" : ""}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {item.colors && (
  <div className="product-color">
    <p>Select Color:</p>
    <div className="color-buttons">
      {item.colors.map(({ color, img, additionalImgs }) => (
        <button
          key={color}
          onClick={() => handleColorChange(color, img, additionalImgs)}  // Pass the right values here
          className={selectedColor === color ? "selected" : ""}
        >
          {color}
        </button>
      ))}
    </div>
  </div>
)}


              <div className="product-quant">
                <p>Quantity</p>
                <div className="product-btns">
                  <button onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</button>
                  <p className="quantity">{quantity}</p>
                  <button onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
                <p className="product-price">${calcPrice().toFixed(2)}</p>
              </div>

              <div className="atc-buy">
                <button onClick={handleAddToCart} className="atc-btn">
                  Add to Cart
                </button>
              </div>
            </div>
          </div>

          <div className="specifications">
          <div className="spec">
            <p className="spec-title">Customer Reviews</p>
            {renderReviews(item.reviews)}
          </div>
            <div className="spec">
              <p className="spec-title">Texture:</p>
              <p className="title-desc">{item.texture}</p>
            </div>
          

          {/* Render Reviews Section */}
        </div>
        </div>
      </div>
      <TrendingSlider />
      <Newsletter />
      <Footer />
    </>
  );
}

export default ProductPage;
