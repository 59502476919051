import '../../index.css';
import CategoriesItemAccessories from "./CategoriesItemAccessories.jsx";
import Footer from "../Footer.jsx";
import Newsletter from "../Newsletter.jsx";

function Accessories() {
  return (
    <>
      <CategoriesItemAccessories />
      <h2 className="center">Coming Soon!</h2>
      <Newsletter />
      <Footer />
    </>
  );
}

export default Accessories;
