import "./TrendingSlider.css";
import { items } from "./AllData";
import { Link } from "react-router-dom";

// Define label-to-color mapping
const labelColors = {
  "New": "#aa3d24",
  "Best Seller": "#202d93", 
  "Limited Edition": "#8b9920", 
};

function TrendingItem() {
  const filteredItems = items.filter((item) => item.id <= 8);

  return (
    <>
      {filteredItems.map((item) => (
        <div key={item.id} className="row-item">
          {item.label && (
            <div
              className="product-label-trending"
              style={{
                backgroundColor: labelColors[item.label] || "#D3D3D3", // Default Light Gray
                color: "#FFF", // White text for contrast
                padding: "5px 10px",
                borderRadius: "5px",
                marginBottom: "5px",
                textAlign: "center",
              }}
            >
              {item.label}
            </div>
          )}
          <Link
            onClick={() => window.top(0, 0)}
            to={`/categories/product/${item.id}`}
          >
            <div className="item-header">
              <img src={item.img} alt="product" />
            </div>
            <div className="item-description">
            <p
  style={{
    color: "black",
    wordWrap: "break-word", // Allows wrapping of long words
    overflowWrap: "break-word", // Ensures proper wrapping in modern browsers
    whiteSpace: "normal", // Resets white space handling to normal
  }}
>
  {item.description}
</p>

              <p className="item-price" style={{ color: "black" }}>
                ${item.price}
              </p>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
}

export default TrendingItem;
