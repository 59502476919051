import React from 'react';
import './RefundPolicyPage.css'; // Ensure to include this file
import CheckoutNavbar from '../components/CheckoutComponents/CheckoutNavbar';
import Footer from '../components/Footer';
import Newsletter from "../components/Newsletter.jsx";


const RefundPolicyPage = () => {
  return (
    <>
      <CheckoutNavbar />
      <div className="page-container-refund">
        <h1 className="page-heading">Refund Policy</h1>
        <div className="policy-content">
          {/* <p>
            To be eligible for a return your item(s) must be unused, unworn, and in the original condition and packaging (if applicable) that you received it in within 30 days of purchase. Unfortunately, after 30 days, we are unable to process any returns or exchanges.
          </p>
          <p>
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If your refund is approved, then your refund will be processed, and a credit will automatically be applied to the original method of payment, within 3 to 6 business days, for the value of the item returned and any applicable taxes associated with the item. Shipping costs are NON-REFUNDABLE.
          </p>
          <p>
            Once your exchange is received, we will send you an email to notify you that we have received your returned item. We will also notify you of the status of your exchange and tracking information for the exchanged item back to you.
          </p>
          <p>
            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs to you are non-refundable. If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee and cannot be held responsible if we do not receive your returned item. Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
          </p> */}
          <p>
          All items are final sale. However, in case of a damaged product or a manufacturing error, we can offer a free replacement or a refund if you contact us within 30 days of product delivery.
          </p>
          <p>
            For ALL returns/exchanges, please e-mail us at pasawayapparel@gmail.com and include your order number to initiate the refund process.
          </p>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default RefundPolicyPage;
