import React from 'react';
import './ourclothing.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WarrenVideo from '../img/reviews/WarrenSmooth.mp4';
import WarrenDelivery from '../img/reviews/WarrenDelivered.jpg';
import Miggy from '../img/reviews/MiguelPinoyAko.jpeg';

function ourclothing() {
  // Sample data for happy customers with videos and images
  const customerPosts = [
    {
      id: 1,
      name: "Miguel Beltran",
      image: Miggy, // Image path
      video: "", // Empty video path (no video for this post)
      review: "I love it! The overall fitting and material is comfortable and snug. It also doesn’t wash away after one wash. Definitely gonna get some more in the future!",
    },
    {
      id: 2,
      name: "Warren San Luiz",
      image: "", // No image for this post
      video: WarrenVideo, // Video path
      // review: "Absolutely love this product! Great quality and fit perfectly!",
      review: "Question: Is it too big or too small? Answer: It's too good!",
    },
    // Add more customers as needed
  ];

  return (
    <>
      <Navbar />
      <div className="gallery-container">
        <h1>Pasaway Reppin</h1>
        <p>
          Here's what our customers are saying about us! Share your moments with
          <strong> #PasawayApparel</strong>.
        </p>
        <div className="gallery-grid">
          {customerPosts.map((post) => (
            <div key={post.id} className="gallery-item">
              {/* Conditional rendering for video or image */}
              {post.video ? (
                <video
                  src={post.video}
                  alt={`${post.name}'s review`}
                  className="gallery-video"
                  autoPlay
                  loop
                  muted
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={post.image}
                  alt={`${post.name}'s review`}
                  className="gallery-image"
                />
              )}
              <div className="gallery-details">
                <h3>{post.name}</h3>
                <p>"{post.review}"</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ourclothing;
