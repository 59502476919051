import "./Footer.css";
import { IconBrandInstagram, IconBrandFacebook, IconBrandTiktok } from "@tabler/icons-react";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer-links">
          {/* <button onClick={() => window.location.href = "#"}>About</button>
          <button onClick={() => window.location.href = "#"}>FAQs</button>
          <button onClick={() => window.location.href = "#"}>News</button> */}
          <button onClick={() => window.location.href = "/pages/size-chart"}>Size Chart</button>
          <button onClick={() => window.location.href = "/pages/refund-policy"}>Refund Policy</button>
          <button onClick={() => window.location.href = "/pages/terms-of-conditions"}>Terms and Conditions</button>
          <button onClick={() => window.location.href = "/pages/privacy-policy"}>Privacy Policy</button>
        </div>
        <div className="footer-social-media">
          <a href="https://www.instagram.com/pasaway.us/" target="_blank" rel="noopener noreferrer">
            <IconBrandInstagram />
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <IconBrandFacebook />
          </a>
          <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
            <IconBrandTiktok />
          </a>
        </div>
        <div className="footer-info">
          <p>All Rights Reserved © 2024 Kulit</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
