import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import CheckoutNavbar from '../components/CheckoutComponents/CheckoutNavbar';
import Footer from '../components/Footer.jsx';
import './Success.css';

function Success() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    const fetchSession = async () => {
      try {
        // const response = await axios.post('http://localhost:5001/payment/checkout-session-status', {
        const response = await axios.post('https://kulit-backend.vercel.app/payment/checkout-session-status/', {
          sessionId: sessionId,
        });

        // console.log('Session Data:', response.data);

        setSession(response.data);
      } catch (error) {
        console.error('Error fetching session:', error);
        setError('Failed to retrieve session details');
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchSession();
    }
  }, [sessionId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!session) {
    return <div>No session found</div>;
  }

  // Extract data with fallback defaults
  const {
    customer_details: {
      email: customer_email = 'Not Provided',
      name: customer_name = 'Not Provided',
      phone: customer_phone = 'Not Provided',
    } = {},
    payment_status,
    line_items = [], // Ensure default to empty array
  } = session;

  const paymentStatus = payment_status === 'paid' ? 'success' : 'failure';

  const products = line_items.map(item => ({
    description: item.description || 'No description available',
    price: item.amount_subtotal / 100,
    quantity: item.quantity || 1,
    img: item.img || 'default_image_url',
    color: item.color || 'N/A',
    size: item.size || 'N/A',
  }));

  const subtotal = products.reduce((total, item) => total + item.price, 0);
  const shippingCostNumber = Number(session.shipping_cost?.amount_total / 100 || 0);
  const discountAmountNumber = Number(session.total_details?.amount_discount / 100 || 0);
  const taxNumber = Number(session.tax || session.total_details?.amount_tax / 100 || 0);

  const finalTotal = subtotal + shippingCostNumber + taxNumber - discountAmountNumber;
  // const finalTotal = taxNumber;

  return (
    <>
      <CheckoutNavbar />
      <div className="confirmation-page">
        {paymentStatus === 'success' ? (
          <div className="confirmation-success">
            <h1>Payment Successful!</h1>
            <p>Thank you for your purchase! The order has been processed successfully and a confirmation email will be sent shortly.</p>
            <p>Please give 2-5 business days to process and deliver your order.</p>
            {/* <p><strong>Order Number:</strong> {order_number}</p> */}
            <p><strong>Customer Email:</strong> {customer_email}</p>
            <p><strong>Customer Name:</strong> {customer_name}</p>
            <p><strong>Customer Phone:</strong> {customer_phone}</p>
            {/* <p><strong>Promo Code Used:</strong> {promoCode}</p> */}
            <br />
            <div className="shipping-info">
              <h3>Shipping Information</h3>
              <p><strong>Name:</strong> {session.shipping_details?.name || 'Not Provided'}</p>
              <p><strong>Address:</strong> {session.shipping_details?.address?.line1 || 'Not Provided'}</p>
              <p>{session.shipping_details?.address?.city || 'Not Provided'}, {session.shipping_details?.address?.state || 'Not Provided'} {session.shipping_details?.address?.postal_code || 'Not Provided'} {session.shipping_details?.address?.country || 'Not Provided'}</p>
            </div>
            <div className="order-summary">
  <h3>Order Summary</h3>
  <ul className="order-products-list">
    {products.length > 0 ? (
      products.map((item, index) => (
        <li key={index} className="product-item">
          <div className="product-img">
            <img src={item.img} alt={item.description} />
          </div>
          <div className="product-info">
            <p>{item.description}</p>
            <p>Color: {item.color}</p>
            <p>Size: {item.size}</p>
            <p>Quantity: {item.quantity}</p>
            <p>Total: ${(item.price).toFixed(2)}</p>
          </div>
        </li>
      ))
    ) : (
      <li>No products found</li>
    )}
  </ul>
  <p><strong>Subtotal:</strong> ${subtotal.toFixed(2)}</p>
  <p><strong>Shipping:</strong> ${(shippingCostNumber).toFixed(2)}</p>
  {discountAmountNumber > 0 && (
    <p><strong>Discount:</strong> -${(discountAmountNumber).toFixed(2)}</p>
  )}
  <p><strong>Sales Tax:</strong> ${(taxNumber).toFixed(2)}</p>
  <h3>Total: ${(finalTotal).toFixed(2)}</h3>
</div>

            <Link to="/" className="go-back-home-btn">Continue Shopping</Link>
          </div>
        ) : (
          <div className="confirmation-failure">
            <h1>Payment Failed</h1>
            <p>Unfortunately, your payment was not successful. Please try again later or contact customer support.</p>
            <Link to="/">Back to Home</Link>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Success;
