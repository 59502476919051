import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../App";
import EmptyCart from "./EmptyCart.jsx";
import CartItem from "./CartItem.jsx";
import axios from 'axios'; // Ensure axios is installed
import './Navbar.css';

function CartWithItems() {
  const { cartItem, setCartItem } = useContext(CartContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const newTotalPrice = cartItem.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    setTotalPrice(newTotalPrice);
  }, [cartItem]);

  const handleCheckout = async () => {
    setLoading(true);
    try {
      // Send the subtotal along with the cartItems
      // const response = await axios.post('http://localhost:5001/payment/create-checkout-session', {
      const response = await axios.post('https://kulit-backend.vercel.app/payment/create-checkout-session', {
        cartItems: cartItem.map(item => {
          let stripePriceId;
  
          if (item.colors) {
            // For items with color variants, find the selected color
            const selectedColor = item.colors.find(color => color.color === item.color);
            if (!selectedColor || !selectedColor.stripePriceId) {
              console.error(`Missing stripePriceId for item ID ${item.id} with color ${item.color}`);
              throw new Error('stripePriceId is required for items with color variants in the cart.');
            }
            stripePriceId = selectedColor.stripePriceId;
          } else if (item.sizes) {
            // For items with size variants, find the selected size
            const selectedSize = item.sizes.find(size => size.size === item.size);
            if (!selectedSize || !selectedSize.stripePriceId) {
              console.error(`Missing stripePriceId for item ID ${item.id} with size ${item.size}`);
              throw new Error('stripePriceId is required for items with size variants in the cart.');
            }
            stripePriceId = selectedSize.stripePriceId;
          } else {
            // For other items, use the stripePriceId directly
            if (!item.stripePriceId) {
              console.error(`Missing stripePriceId for item ID ${item.id}`);
              throw new Error('stripePriceId is required for all items in the cart.');
            }
            stripePriceId = item.stripePriceId;
          }
  
          return {
            stripePriceId,                      // Pass the correct price ID
            quantity: item.quantity || 1,       // Default to 1 if not specified
            name: item.description || 'N/A',   // Fallback name
            color: item.color || 'N/A',        // Fallback color
            size: item.size || 'N/A',          // Fallback size
            price: item.price,                 // Optional, for reference
          };
        }),
        subtotal: totalPrice, // Add subtotal here
      });
  
      const { id } = response.data;
      // const stripe = window.Stripe('pk_test_51P7ai8LO5J7ORzPKAln0xJ8BGguERs7s7DgzjpeptgdRuEqDIlvz2SWAoQXG7KBIIo1vMSYOvRwg2tVQb9SgwrbP00titp7XqH');
      const stripe = window.Stripe('pk_live_51P7ai8LO5J7ORzPKqsPJmmY7BUgwIRhmlRz8euajFDUYywa5uTXxdxfBPESfaeBUt0HCO4wk88YNaYpIw5fvlI8H00JMrVPj0o');
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
  
      if (error) {
        console.error('Error during Stripe checkout redirect:', error);
        setError('Checkout session creation failed.');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Failed to create checkout session. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  
  
  return (
    <>
      <div className="full-cart-div">
        <div className="full-cart">
          {cartItem.length !== 0 ? (
            cartItem.map((item, id) => (
              <CartItem key={id} item={item} setCartItem={setCartItem} />
            ))
          ) : (
            <EmptyCart />
          )}
        </div>
      </div>
      <div className="subtotal-div">
        <div className="sub-right">
          <p>Subtotal</p>
          <p className="total-price">${totalPrice.toFixed(2)}</p>
        </div>
        <div className="sub-left">
          <button onClick={handleCheckout} disabled={loading}>
            {loading ? 'Processing...' : 'Go to Checkout'}
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>} {/* Show error if exists */}
    </>
  );
}

export default CartWithItems;
