import React from 'react';
import './SizeChartPage.css'; // Ensure to include this file
import CheckoutNavbar from '../components/CheckoutComponents/CheckoutNavbar';
import Footer from '../components/Footer';
import Newsletter from "../components/Newsletter.jsx";

const SizeChartPage = () => {

  return (
    <>
      <CheckoutNavbar />
      <div className="page-container-size">
      <h1 className="page-heading">Size Chart</h1>
        <h1 className="page-subheading">Sweatshirts</h1>
        <table className="size-chart-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Width (inches)</th>
              <th>Length (inches)</th>
              <th>Sleeve Length (inches)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Small</td>
              <td>20.08''</td>
              <td>27.17''</td>
              <td>33.50''</td>
            </tr>
            <tr>
              <td>Medium</td>
              <td>22.05''</td>
              <td>27.95''</td>
              <td>34.50''</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>24.02''</td>
              <td>29.13''</td>
              <td>35.50''</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>25.98''</td>
              <td>29.92''</td>
              <td>36.50''</td>
            </tr>
          </tbody>
        </table>

        <h1 className="page-subheading">Crewnecks</h1>
        <table className="size-chart-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Width (inches)</th>
              <th>Length (inches)</th>
              <th>Sleeve Length (inches)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Small</td>
              <td>20.00''</td>
              <td>27.00''</td>
              <td>33.50''</td>
            </tr>
            <tr>
              <td>Medium</td>
              <td>22.01''</td>
              <td>28.00''</td>
              <td>34.50''</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>24.00''</td>
              <td>29.00''</td>
              <td>35.50''</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>26.00''</td>
              <td>30.00''</td>
              <td>36.50''</td>
            </tr>
          </tbody>
        </table>
        <h1 className="page-subheading">T-Shirts</h1>
        <table className="size-chart-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>Width (inches)</th>
              <th>Length (inches)</th>
              <th>Sleeve Length (inches)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Small</td>
              <td>18.00''</td>
              <td>28.00''</td>
              <td>8.23''</td>
            </tr>
            <tr>
              <td>Medium</td>
              <td>20.00''</td>
              <td>29.00''</td>
              <td>8.50''</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>22.00''</td>
              <td>30.00''</td>
              <td>8.74''</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>24.00''</td>
              <td>31.00''</td>
              <td>9.02''</td>
            </tr>
          </tbody>
        </table>
        </div>
        <Newsletter />
      <Footer />
    </>
  );
};

export default SizeChartPage;
