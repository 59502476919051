import React from 'react';
import './PrivacyPolicyPage.css'; // Ensure to include this file
import CheckoutNavbar from '../components/CheckoutComponents/CheckoutNavbar';
import Footer from '../components/Footer';
import Newsletter from "../components/Newsletter.jsx";


const PrivacyPolicyPage = () => {
  return (
    <>
      <CheckoutNavbar />
      <div className="page-container-privacy">
      <div className="page-content">
      <h1 className="page-heading">Privacy Policy</h1>
      <p>We at <a href="https://www.pasaway.us" className="link">www.pasaway.us</a> consider the privacy of our visitors to be important. This privacy policy document describes in detail the types of information that is collected and recorded by us and how we use it.</p>
      <section>
        <h2>Consent</h2>
        <p>By using this website and our services you are agreeing to the terms of this Privacy Policy and, as applicable, our Terms of Service.</p>
        <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our privacy practices or for other operational, legal, or regulatory reasons. If we make material changes to this Privacy Policy, we will give you notice of such changes by posting the revised policy on this Website, and where appropriate, by other means. By continuing to use this Website after these changes are posted, you agree to the revised policy.</p>
      </section>
      <section>
        <h2>What information do we collect?</h2>
        <p>We collect two types of information regarding our Visitors and Users:</p>
        <p>Un-identified and non-identifiable information pertaining to a Visitor or un-identified User, which may be made available to us, or collected automatically via his/her use of the services (“Non-personal Information”). Such Non-personal Information does not enable us to identify the Visitor or User from whom it was collected. The Non-personal Information collected by us mainly consists of technical and aggregated usage information, such as Visitors’ and Users’ browsing and ‘click-stream’ activity on the Services, session heat maps and scrolls, non-identifying information regarding the Visitor’s or User’s device, operating system, internet browser, screen resolution, language and keyboard settings, internet service provider, referring/exit pages, date/time stamps, etc.</p>
        <p>Individually identifiable information, namely information that identifies an individual or may with reasonable efforts cause the identification of an individual, or may be of private or sensitive nature (“Personal Information”). The Personal Information collected by us mainly consists of contact details (e.g., e-mail address or phone number), billing details (name, physical billing address, payment method and transaction details), details regarding a browsing or usage session (IP address, Geo-location and/or device unique identifier), details regarding connected third-party accounts (such as the e-mail or username for a connected PayPal account), scanned identification documents provided to us (such as an ID card, driver’s license, passport or official company registration documents), correspondences (including those made through or uploaded to our services), and any other Personal Information provided to us by Visitors and/or Users through their access to and/or use of our products or services. For the avoidance of doubt, any Non-personal Information that is connected or linked to Personal Information (for example, in order to improve the product or  Services we offer) is deemed and treated by us as Personal Information, as long as such connection or linkage exists.</p>
      </section>
      <section>
        <h2>How do we collect information?</h2>
        <p>There are two main methods we use:</p>
        <p>We collect information through your use of our website, products and services. In other words, when you are visiting or using our website to buy our products  or services , including when you browse the website or register a user account, edit your user information and content, and/or download and use any of our products or  services , we are aware of it any may gather, collect and record such uses, sessions and related information, either independently or with the help of third-party services , including through the use of “cookies” and other tracking technologies.</p>
        <p>We collect information which you provide us voluntarily. For example, we collect the Personal Information you provide us when you register or purchase our products or services; when you sign-in to our to shop for our products or services; when you place purchases and/or register domain names; when you submit or upload such Personal Information as you use any of our products or services; and/or when you contact us directly.</p>
      </section>
      <section>
        <h2>Why do we collect such information?</h2>
        <p>We collect such Non-personal and Personal Information for the following purposes:</p>
        <p>We use customer contact information to fulfil orders and to communicate with you.</p>
        <p>We also collect information:</p>
        <p>To further develop, customize and improve our products or  services, based on Visitors’ and Users’ common or personal preferences, experiences and difficulties;</p>
        <p>To provide our Users with ongoing customer assistance and technical support;</p>
        <p>To be able to contact our Visitors and Users with general or personalized service-related notices and promotional messages;</p>
        <p>To facilitate, sponsor and offer certain contests, events and promotions, determine participants’ eligibility, monitor performance, contact winners and grant prizes and benefits;</p>
        <p>To comply with any applicable laws and regulations.</p>
      </section>
      <section>
        <h2>Third Party Sharing</h2>
        <p>We may share Non Personal and Personal information of our visitors and users with various third parties, including certain service providers, law enforcement officials and application developers.</p>
        <p>We do not sell, rent, trade or otherwise transfer any personal information to any third party without your express permission (unless obliged to do so under applicable laws or by order of the competent authorities) except as set out in this Privacy Policy.</p>
        <p>We need to provide certain information to third parties who assist us in providing our products and services and developing our markets.</p>
        <p>The information may be shared solely in accordance with this policy.</p>
        <p>You will be directed to Stripe.com to pay for our services.</p>
        <p>You should consult the privacy policies of Stripe.com before accepting or using their services or the services of any other third party that may be linked to our website or services.</p>
      </section>
      <section>
        <h2>Children's Information</h2>
        <p>We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity.</p>
        <p>We do not knowingly collect any personally identifiable information from children under the age of 13. If a parent or guardian believes that we have in our database the personally-identifiable information of a child under the age of 13, please contact us immediately (using the contact link  and we will use our best efforts to promptly remove such information from our records.</p>
      </section>
      <section>
        <h2>Request Information</h2>
        <p>You may ask us at any time what Personally Identifiable information about you we are processing and we will respond as soon as possible, subject to any legal restrictions.  For security purposes, you may only make your request by sending us a signed document and verifying your identity with a valid driver licence or passport or other official proof of identity.</p>
        <p>If you do have a user account with us, you may, update or correct your Personally Identifiable information by accessing and amending your profile entries in your user account dashboard.</p>
      </section>
      <section>
        <h2>How long do we keep your information</h2>
        <p>
        We will keep your information for as long as is necessary to provide the services or products we have agreed  to provide, to carry out any of the purposes set out in this Privacy Policy, and to comply with applicable legislation, and any applicable orders of regulatory bodies or courts.</p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at <a href="mailto:pasawayapparel@gmail.com" className="link">pasawayapparel@gmail.com</a>.</p>
      </section>
      </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
